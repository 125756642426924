import React from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const NameWrapper = styled.div`
  margin: 0;
`;

const Title = styled.h3`
  ${fontstack.primary}
  ${type("titleB")}
  color: var(--brand-grey2);
  display: inline-block;
  margin: 0;
  transition: color 0.2s ease;
`;

const TitleTr = styled.div`
  ${fontstack.traditional}
  font-size: 27px;
  line-height: 30px;
  color: var(--brand-grey2);
  display: inline-block;
  margin: 0;
  transition: color 0.2s ease;
`;

const Part = styled.div`
  ${fontstack.secondary}
  ${type("subheaderC")}
  color: var(--brand-grey2);
  display: inline-block;
  text-transform: uppercase;
  margin: 0 0 0 15px;
`;

const PartTr = styled.div`
  ${fontstack.traditional}
  font-size: 11px;
  line-height: 23px;
  color: var(--brand-grey2);
  display: inline-block;
  text-transform: uppercase;
  margin: 0 0 0 15px;
`;

const Index = styled.div`
  position: relative;
  ${fontstack.secondary}
  font-size: 12px;
  color: var(--brand-grey2);
  width: 15px;

  ${media.large` 
    position: absolute;
    left: 25px;
    width: auto;
  `}
`;

const DefinitionWrapper = styled.div`
  margin: 40px 0 0;
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const DefinitionText = styled.div`
  ${fontstack.secondary}
  ${type("subheaderB")}
  color: var(--brand-grey1);
  margin: 0 0 15px;
`;

const DefinitionTextTr = styled(DefinitionText)`
  ${fontstack.traditionalSans}
`;

const IndexNameWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 25px 15px;
  border-right: 1px solid var(--brand-grey3);
  border-bottom: 1px solid var(--brand-grey3);
  max-height: 200px;
  background: ${(props) =>
    props.active ? "white" : "var(--brand-background)"};
  transition: all 0.2s ease;
  transform-origin: top left;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  ${media.large` 
    max-height: unset;
    padding: 18px 40px 0;
    min-height: 450px;
  `}

  &:hover {
    ${media.large` 
      max-height: unset;
    `}
  }

  ${({active}) => active && `

    z-index: 1;
    max-height: 450px;

    ${media.large` 
      max-height: unset;
    `}

  `}

  & ${DefinitionWrapper} {
    opacity: ${(props) => (props.active ? 1 : 0)};
  }

  & ${Title}, & ${TitleTr} {
    color: ${(props) => (props.active ? "var(--brand-primary)" : "var(--brand-grey2)")};
  }

  ${media.large` 
    &:hover ${DefinitionWrapper} {
      opacity: 1;
    }

    &:hover ${Title}, &:hover ${TitleTr} {
      color: var(--brand-primary);
    }
  `}
`;

const Definition = ({ node, index, active, ...rest }) => {
  return (
    <Wrapper active={active} {...rest}>
      <IndexNameWrapper>
        <Index>{index}</Index>
        <div>
          <NameWrapper>
            <Title>{node.word.en}</Title>
            <Part>{node.part && node.part.en}</Part>
          </NameWrapper>
          <NameWrapper>
            <TitleTr>{node.word.tr}</TitleTr>
            <PartTr>{node.part && node.part.tr}</PartTr>
          </NameWrapper>
        </div>
      </IndexNameWrapper>
      <DefinitionWrapper>
        <DefinitionText>
          {node.definition.en}
        </DefinitionText>
        <DefinitionTextTr>
          {node.definition.tr}
        </DefinitionTextTr>
      </DefinitionWrapper>
    </Wrapper>
  );
};

export default Definition;
