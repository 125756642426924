import React from "react";
import styled from "styled-components";
import { PadSection } from "../Section/Section";
import { themes } from "utils/themes";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const TitleWrapper = styled.h2`
  ${fontstack.secondary}
  ${type("subheaderC")}
  ${themes("text")};
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;

  ${media.large` 
    ${type("subheaderB")}
  `}

  ${media.xxlarge` 
    ${type("subheaderA")}
  `}
`;

const TitlePlus = styled.span`
  margin: 0 4px 0 0;
  display: inline-block;

  ${media.large` 
    margin: 0 15px 0 0;
  `}
`;

export const Title = ({ theme, children, ...rest }) => {
  return (
    <TitleWrapper theme={theme} {...rest}>
      <TitlePlus>+</TitlePlus>
      {children}
    </TitleWrapper>
  );
};

const SemiWrapper = styled.span`
  font-weight: 500;
`;

export const Semi = ({ children, ...rest }) => {
  return (
    <SemiWrapper {...rest}>
      {children}
    </SemiWrapper>
  );
};

const LinkWrapper = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey2);
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;
  text-decoration: underline;

  ${({ small }) =>
    small &&
    `
    ${type("subheaderC")}
  `}

  ${media.large`
  ${type("subheaderB")}
  `}

  ${media.xxlarge` 
    ${({ small }) =>
      small &&
      `
      ${type("subheaderB")}
    `}
  `}
`;

export const CTA = ({ children, ...rest }) => {
  return <LinkWrapper {...rest}>{children}</LinkWrapper>;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;

  ${media.xxlarge` 
    margin: 0 0 25px;
  `}
`;

export const Header = ({ theme, children, ...rest }) => {
  return (
    <HeaderWrapper theme={theme} {...rest}>
      {children}
    </HeaderWrapper>
  );
};

const LineWrapper = styled.div`
  padding: 0 0 10px;
  border-bottom: 1px solid var(--brand-primary);
  margin: ${(props) => (props.nomargin ? "0" : "0 0 27px")};
`;

const LinePad = styled(PadSection)`
  display: flex;
  justify-content: space-between;
`;

export const LineHeader = ({ theme, children, nomargin, ...rest }) => {
  return (
    <LineWrapper theme={theme} nomargin={nomargin} {...rest}>
      <LinePad>
        {children}
      </LinePad>
    </LineWrapper>
  );
};


const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    props.first
      ? "15px 15px 15px 15px"
      : props.last
      ? "15px 15px 15px 15px"
      : "15px"};

  border-top: ${(props) =>
    props.first
      ? "1px solid var(--brand-grey2)"
      : props.last
      ? "none"
      : "1px solid var(--brand-grey2)"};

  border-bottom: 1px solid var(--brand-grey2);
  margin-top: ${(props) => (props.last ? "25px" : "0")};

  ${media.large` 
    padding: ${(props) =>
      props.first
        ? "15px 15px 15px 0"
        : props.last
        ? "15px 0 15px 15px"
        : "15px"};

    border-top: ${(props) =>
      props.first
        ? "1px solid var(--brand-grey2)"
        : props.last
        ? "1px solid var(--brand-grey2)"
        : "1px solid var(--brand-grey2)"};

    margin-top: 0;
  `}

  ${media.xxlarge` 
    padding: ${(props) =>
      props.first
        ? "20px 20px 20px 0"
        : props.last
        ? "20px 0 20px 20px"
        : "20px"};
  `}
`;

const BoxTitle = styled.h2`
  ${fontstack.secondary}
  ${type("subheaderC")}
  ${themes("text")};
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;

  ${media.xxlarge` 
    ${type("subheaderA")}
  `}
`;

export const BoxHeader = ({ theme, title, first, last, children, ...rest }) => {
  return (
    <BoxWrapper theme={theme} first={first} last={last} {...rest}>
      { first ? <Title theme={theme}>{title}</Title> : last ? <BoxTitle theme={theme}>{title}</BoxTitle> : null}
      {children}
    </BoxWrapper>
  );
};
