import React from "react";
import styled from "styled-components";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import { LineHeader, Title, Semi } from "components/Module/Module";
import { PadSection } from "components/Section/Section";
import GraphQLErrorList from "components/graphql-error-list";
import SEO from "components/seo";
import Layout from "containers/layout";
import Definition from "components/Definition/Definition";
import { media } from "utils/mediaQuery";

export async function getServerData({ query }) {
  const sanityClient = require("@sanity/client");
  const slice = require("lodash.slice");
  const shuffle = require("lodash.shuffle");

  const client = sanityClient({
    projectId: "72ij7d0h",
    dataset: "production",
    apiVersion: "2021-10-21", // use current UTC date - see "specifying API version"!
    token: `${process.env.GATSBY_SANITY_READ_TOKEN || ""}`, // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  });

  const standardQuery =
    "*[_type == 'definition']{ _id, word, part, definition }";

  const targetQuery =
    "*[_type == 'definition' && _id == $id]{ _id, word, part, definition }";
  const baseQueryWithout =
    "*[_type == 'definition' && _id != $id]{ _id, word, part, definition }";
  const params = { id: query.id };

  try {
    let target = null;
    let data = null;

    if (query && query.id) {
      target = await client.fetch(targetQuery, params);
      data = await client.fetch(baseQueryWithout, params);
    } else {
      data = await client.fetch(standardQuery);
    }

    // Get randomization
    data = shuffle(data);
    data = slice(data, 0, 50);

    return {
      props: {
        query,
        data,
        target,
      },
    };
  } catch (error) {
    console.log(error.msg);
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${media.large` 
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.xxlarge` 
    grid-template-columns: repeat(5, 1fr);
  `}
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;
`;

const GlossaryPage = (props) => {
  const { serverData, errors } = props;
  const [ Active, setActive ] = React.useState();

  const definitions = (serverData || {}).data;
  const targetDefinitions = (serverData || {}).target;

  const toggleActive = (index) => {
    setActive(Active === index ? null : index);
  };

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout pageElements={
      <CustomLineHeader>
          <Title>THE NEW MOON <Semi>GLOSSARY</Semi></Title>
      </CustomLineHeader>
      }
    >
      <SEO />
      <Grid>
        {targetDefinitions &&
          targetDefinitions.map((node, i) => (
            <Definition key={i} node={node} index={i + 1} active/>
          ))}
        {definitions &&
          definitions.map((node, i) => (
            <Definition
              key={i}
              node={node}
              index={i + 1}
              active={Active === i ? true : false}
              onClick={() => toggleActive(i)}
            />
          ))}
      </Grid>
    </Layout>
  );
};

export default GlossaryPage;
